import React, { FC, useState } from "react";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import * as styles from "./brandDetailsScreen.module.sass";

export interface CraftoriesProps {
    isCraftory: boolean;
    title: string;
    heading1?: string;
    p1: string;
    heading2?: string;
    p2?: string;
    img1: any;
    img2: any;
    img3?: any;
}

const Craftories: FC<CraftoriesProps> = (props) => {
    const {title, heading1, p1, heading2, p2, img1, img2, img3} = props;
    const { isMobile } = useIsMobileViewport();
    return (
        <div className='flex flex-col gap-12'>
            <div className='flex flex-row justify-between'>
                <div className='flex flex-col md:flex-1 gap-6'>
                    <h1 className='uppercase font-bold text-7xl'>
                        {title}
                    </h1>
                    {isMobile && (
                        <div>
                            <img src={img2} />
                        </div>
                    )}
                    <div className='gap-2 flex flex-col'>
                        <h5 className='uppercasse font-bold text-sm'>
                            {heading1}
                        </h5>
                        <p className='text-xs'>
                            {p1}
                        </p>
                    </div>
                    <div className='gap-2 flex flex-col'>
                        <h5 className='uppercasse font-bold text-sm'>
                            {heading2}
                        </h5>
                        <p className='text-xs'>
                            {p2}
                        </p>
                    </div>
                    {!img3 && !isMobile && (
                        <div>
                            <img src={img2} />
                        </div>
                    )}
                </div>
                {!isMobile && (
                    <div className=' flex-1 pl-10 flex flex-col self-end	'>
                        <img src={img1} />
                    </div>
                )}
            </div>
            <div className='flex flex-col md:flex-row justify-between gap-4'>
                {img3 && !isMobile && (
                    <div>
                        <img src={img2} />
                    </div>
                )}
                {isMobile && (
                    <div className='flex flex-col'>
                        <img src={img1} />
                    </div>
                )}
                {img3 && (
                    <div>
                        <img src={img3} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Craftories;
