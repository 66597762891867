import React from "react";
import type { HeadFC } from "gatsby";
import {Kramer} from "../../screens/BrandsScreen/Brands";

const KramerBrandPage = () => {
    return <Kramer />;
};

export default KramerBrandPage;

export const Head: HeadFC = () => <title>Our Brands</title>;
