import React, { FC, useState } from "react";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import ImageCard, { ImageCardProps } from "../ImageCard";
import EleS1Img1 from "../../assets/images/Desktop/OurBrands/Gibson/Collections/Ele_S1_Img1.jpg";
import EleS1Img2 from "../../assets/images/Desktop/OurBrands/Gibson/Collections/Ele_S1_Img2.jpg";
import EleS1Img3 from "../../assets/images/Desktop/OurBrands/Gibson/Collections/Ele_S1_Img3.png";
import * as styles from "./brandDetailsScreen.module.sass";


export interface Collection {
    title: string;
    products: ImageCardProps[];
}

export interface CollectionProps {
    isCollection: boolean;
    collections: Collection[];
}

const Collections: FC<CollectionProps> = ({ collections }) => {
    const { isMobile } = useIsMobileViewport();
    return (
        <div className='flex flex-col'>
            {collections.map((collectionData, id) => {
                return (
                    <div
                        className={`flex flex-col ${
                            id === collections.length - 1
                                ? " "
                                : "border-b-[1px]"
                        } pb-8 ${id === 0 ? " " : "py-12"}`}>
                        <h1 className='uppercase font-bold text-4xl md:text-5xl md:max-w-[60%] mb-12'>
                            {collectionData.title}
                        </h1>
                        <div className='flex gap-8 flex-wrap justify-start'>
                            {collectionData.products.map((data, id) => {
                                return <ImageCard {...data} isPdtCard />;
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Collections;
