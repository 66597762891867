// extracted by mini-css-extract-plugin
export var brandDetailsScreen = "brandDetailsScreen-module--brandDetailsScreen--093c6";
export var content = "brandDetailsScreen-module--content--10d51";
export var desktopMargin = "50px";
export var doublexl = "1536";
export var lg = "1024";
export var md = "768";
export var mobileMargin = "18px";
export var scrollbar = "brandDetailsScreen-module--scrollbar--c349f";
export var sm = "640";
export var titleBar = "brandDetailsScreen-module--titleBar--65ff9";
export var xl = "1280";