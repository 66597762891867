import React, { FC, useState } from "react";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import Button from "../Button";
import { navigate } from "gatsby";
import PopUp from "../PopUp";
import * as styles from "./brandDetailsScreen.module.sass";

export interface TitleBarProps {
    Links: React.ReactNode;
    title: string;
    brandTree?: JSX.Element;
    brandName?: string;
}

const TitleBar: FC<TitleBarProps> = (props) => {
    const { brandName, Links, title, brandTree } = props;
    const {isMobile} = useIsMobileViewport();
    return (
        <div
            className={`${
                !isMobile ? "container-padding" : ""
            } shadow-[0_8px_13px_0px_rgba(0,0,0,0.1)] md:shadow-[0_3px_13px_0px_rgba(0,0,0,0.15)] pb-6 md:py-6 flex flex-col md:flex-row justify-between items-start md:items-end ${
                styles.titleBar
            }`}>
            <div
                className={`${
                    isMobile ? "container-padding" : ""
                } gap-[1rem] flex flex-col items-start w-auto`}>
                {isMobile && (
                    <Button
                        hasArrow
                        arrowColor='black'
                        arrowAtStart
                        classes='p-0'
                        internalLink={`/overview/${brandName}`}>
                        Back to {brandName ? brandName : 'brands'}
                    </Button>
                )}
                <div className='flex items-center gap-2'>
                    <h3 className='text-xl uppercase'>{title}</h3>
                    {brandTree && <PopUp brandTree={brandTree} />}
                </div>
            </div>
            <div
                className={`${
                    isMobile ? "container-padding" : ""
                } flex justify-end w-auto`}>
                {Links}
            </div>
        </div>
    );
};

export default TitleBar;
