import React, { FC } from 'react'
import { BrandInfoDesktop } from '../../../components/BrandCard'
import BrandDetailsScreen from '../../../components/BrandDetailsScreen';
import Header from '../../../components/Header';
import useIsMobileViewport from '../../../hooks/useIsMobileViewport';
import { BrandsData } from '../data';
import { BrandCardProps, brands } from '../types';
import { useLocation } from "@reach/router";
import queryString from "query-string";
import * as styles from "./brands.module.sass";


export interface BrandsProps {
    brandName: brands;
}

const Brands: FC<BrandsProps> = ({ brandName }) => {
    const currentMenu = useLocation().search;
    const menu = queryString.parse(currentMenu);
    const menuId = Number(menu?.menuId || 0);

    const { isMobile } = useIsMobileViewport();
    const data: BrandCardProps = BrandsData(isMobile)[brandName];
    return (
        <div className='bg-white'>
            <Header mobileAfterScrollView={isMobile} />
            <div className={`relative ${styles.brands} flex`}>
                {!isMobile && <BrandInfoDesktop isActive {...data} />}
                <BrandDetailsScreen
                    {...data.subMenus[menuId]}
                    brandName={brandName}
                />
            </div>
        </div>
    );
};

export const Epiphone = () => <Brands brandName='epiphone' />;
export const Gibson = () => <Brands brandName='gibson' />;
export const Kramer = () => <Brands brandName='kramer' />;
export const Krk = () => <Brands brandName='krk' />;
export const Maestro = () => <Brands brandName='maestro' />;
export const MesaBoogie = () => <Brands brandName='mesaboogie' />;

export default Brands;
