import React, { FC, useState } from "react";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import TitleBar from "./TitleBar";
import Tabs from "../Tabs";
import { TabData } from "../Tabs/types";
import { BrandDetailsScreenProps } from "./types";
import Craftories, { CraftoriesProps } from "./Craftories";
import Collections, { CollectionProps } from "./Collections";
import * as styles from "./brandDetailsScreen.module.sass";

const BrandDetailsScreen: FC<BrandDetailsScreenProps> = (props) => {
    const { name,brandName, title, data, brandTree } = props;
    const { TabLinks, TabContent, activeTab } = Tabs({ data });
    const {isMobile} = useIsMobileViewport();
    return (
        <div className={`w-full bg-white ${styles.brandDetailsScreen}`}>
            <TitleBar
                Links={data?.length > 1 ? <TabLinks activeTabClass={`border-${brandName}`}/> : null}
                title={title}
                brandTree={brandTree}
                brandName={brandName}
            />

            <TabContent>
                {(tabData, id) => (
                    <div
                        className={` py-12 overflow-x-hidden overflow-y-auto scroll ${
                            styles.content
                        } ${!isMobile ? styles.scrollbar : ""}`}>
                        {(tabData.content as CraftoriesProps)?.isCraftory && (
                            <Craftories
                                {...(tabData.content as CraftoriesProps)}
                            />
                        )}
                        {(tabData.content as CollectionProps)?.isCollection && (
                            <Collections
                                {...(tabData.content as CollectionProps)}
                            />
                        )}
                    </div>
                )}
            </TabContent>
        </div>
    );
};

export default BrandDetailsScreen;
