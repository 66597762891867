import React, { FC, useState } from 'react';
import GibsonGuitars from "../../assets/images/GibsonGuitars.png";
import CrossSign from '../../assets/svg/CrossIcon';
import GibsonLogoBlack from "../../assets/svg/Desktop/BrandSelect/Gibson/GibsonLogoBlack.svg"; 
import Button from '../Button';
import * as styles from "./popup.module.sass"; 

export interface PopupProps {
    brandTree: JSX.Element;
}

const PopUp: FC<PopupProps> = ({ brandTree }) => {
    const [isActive, setIsActive] = useState(false);
    return (
        <div>
            <Button
                classes='bg-black text-white p-[8px] lowercase h-[15px] w-[15px] relative bottom-[-1px] rounded-full text-[12px] font-[serif] text-sm'
                onClick={() => {
                    setIsActive(!isActive);
                }}>
                i
            </Button>
            <div
                className={`${
                    isActive ? "" : "hidden"
                } h-full transition-all duration-1000 flex items-center justify-center overflow-y-auto overflow-x-hidden fixed z-50 w-full inset-0 ${
                    styles.popupBg
                }`}>
                <div className='h-[28rem] smScreens:h-[36rem] transition-all duration-1000 relative bg-flash-white p-2 md:p-11 md:pt-20 shadow-[4px_4px_12px_0px_rgba(0,0,0,0.2)]'>
                    <button
                        type='button'
                        className='absolute top-0 right-0 mr-4 mt-4 p-2 bg-black text-white z-[100]'
                        data-modal-toggle='defaultModal'
                        onClick={() => {
                            setIsActive(!isActive);
                        }}>
                        <CrossSign classes='h-5 w-5' />
                        <span className='sr-only'>Close modal</span>
                    </button>
                    <div className='flex flex-col gap-[2rem] w-[21rem] lg:w-[58rem] h-full'>
                        {brandTree}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopUp;