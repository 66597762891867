import React, { FC, useState } from 'react'; 
import Carousel from '../Carousel';
import useIsMobileViewport from '../../hooks/useIsMobileViewport';
import Button from '../Button';
import NewReleaseTag from "../../assets/svg/Desktop/BrandOverview/NewReleaseTag.svg";
import { Placeholder } from 'gatsby-plugin-image';
import * as styles from "./imageCard.module.sass";


export interface ImageCardProps {
    isPdtCard?: boolean;
    image: any;
    title?: string;
    linkPlaceHolder: string;
    link: string;
    classes?: string;
    hasNewReleaseTag?: boolean;
}

const ImageCard: FC<ImageCardProps> = (props) => {
    const {
        isPdtCard = false,
        image,
        title,
        linkPlaceHolder,
        link,
        hasNewReleaseTag,
        classes,
    } = props;
    const { isMobile } = useIsMobileViewport();

    if (isPdtCard)
        return (
            <div
                className={`${classes} flex flex-col relative top-[-10px] shadow-[2px_2px_10px_rgba(0,0,0,0.2)]`}>
                <img src={image} className='w-[350px] h-[230px]' />
                <div className='flex my-4 px-4'>
                    <Button
                        classes='p-0'
                        hasArrow
                        arrowColor='#000000'
                        externalLink={link}>
                        {linkPlaceHolder}
                    </Button>
                </div>
            </div>
        );
    
    return (
        <div className={` ${classes} flex flex-col max-w-[350px]`}>
            <img src={image} />
            <h5 className='font-bold text-xl mt-4'>{title}</h5>
            <div className='flex'>
                <Button
                    classes='p-0'
                    hasArrow
                    arrowColor='#000000'
                    externalLink={link}>
                    {linkPlaceHolder}
                </Button>
            </div>
            {hasNewReleaseTag && (
                <img
                    src={NewReleaseTag}
                    className='absolute top-[-3%] md:top-[5%] right-[8px] md:right-[-8px]'
                />
            )}
        </div>
    );
};

export default ImageCard;